<style>
  .bmc-button img {
    height: 34px;
    width: 35px;
    margin-bottom: 1px;
    box-shadow: none;
    border: none;
    vertical-align: middle;
  }
  .bmc-button {
    padding: 7px 15px 7px 10px;
    line-height: 35px;
    height: 51px;
    text-decoration: none;
    display: inline-flex;
    color: var(--bg-text);
    border-radius: 8px;
    border: 1px solid var(--secondary);
    font-size: 24px;
    letter-spacing: 0.6px;
    margin: 18px auto;
    font-family: cursive;
    transition: 300ms all;
  }
  .bmc-button:hover,
  .bmc-button:active,
  .bmc-button:focus {
    background: var(--secondary);
    color: var(--bg);
    font-size: 26px;
  }
</style>

<a
  title="Best and easy way to say thank you"
  class="bmc-button"
  target="_blank"
  href="https://www.buymeacoffee.com/todar">
  <img
    src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
    alt="Buy me a coffee" />
  <span style="margin-left:5px;font-size:24px">Buy me a coffee</span>
</a>
